import axios from "@axios";
import Api from "../../../apiServices/Api";

export default {
  namespaced: true,
  state: {
    calendarOptions: [
      {
        color: "success",
        label: "Contract Begin",
      },
      {
        color: "danger",
        label: "Contract Expired/Expiring",
      },
      {
        color: "success",
        label: "Milestone Paid",
      },
      {
        color: "danger",
        label: "Milestone To Be Paid",
      },
      {
        color: "success",
        label: "Royalty Report  Generated",
      },
      {
        color: "danger",
        label: "Royalty Report To Be Generated",
      },
      {
        color: "success",
        label: "Available Rights To Sell",
      },
      {
        color: "success",
        label: "Available Exclusive Rights To Sell",
      },
      {
        color: "success",
        label: "Sold Rights",
      },
      {
        color: "success",
        label: "Exclusive Sold Rights",
      },
      {
        color: "success",
        label: "Rights Recursion",
      },
    ],
    selectedCalendars: [],
  },
  getters: {},
  mutations: {
    SET_SELECTED_EVENTS(state, val) {
      state.selectedCalendars = val;
    },
  },
  actions: {
    fetchEvents(ctx, { calendars, calendarsDate }) {
      // return new Promise((resolve, reject) => {
      //   Api()
      //     .post("/revenue/getCalenderEvents", {
      //       params: {
      //         calendars: calendars.join(","),
      //         calendarsDate: calendarsDate,
      //       },
      //     })
      //     .then((response) => resolve(response))
      //     .catch((error) => reject(error));
      // });
    },
    addEvent(ctx, { event }) {
      return new Promise((resolve, reject) => {
        axios
          .post("/apps/calendar/events", { event })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    updateEvent(ctx, { event }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/apps/calendar/events/${event.id}`, { event })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    removeEvent(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/apps/calendar/events/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  },
};
